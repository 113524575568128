import { FC } from 'react';
import { ColumnConfig } from '../../../../models/TableView';
import { CurrencyFilterOptions, CurrencyFilterValue } from '../../../../models/TableViewFilters';
import { useTranslation } from 'react-i18next';
import Badge from '../../../shared/badge/Badge';

const ProgressFilterOverview: FC<ColumnConfig> = (props) => {
  const { filter } = props;
  const currencyFilter = filter as CurrencyFilterValue | undefined;
  const { t } = useTranslation(['table-view', 'common']);

  return (
    <div>
      {currencyFilter && (
        <div className="flex items-center gap-2">
          {currencyFilter.selectedOption === CurrencyFilterOptions.EXACT && (
            <Badge
              textClass="text-dpm-gray-2 !font-normal"
              text={t('filters.currency.combined-exact', { value: currencyFilter.exact?.amount, currency: currencyFilter.exact?.currencyCode })}
            />
          )}
          {currencyFilter.selectedOption === CurrencyFilterOptions.BETWEEN && (
            <Badge
              textClass="text-dpm-gray-2 !font-normal"
              text={t('filters.currency.combined-between', {
                option: t(`filters.progress.${currencyFilter.selectedOption}`),
                start: currencyFilter.between?.start?.amount ?? 0,
                end: currencyFilter.between?.end?.amount ?? 0,
                currency: currencyFilter.between?.start?.currencyCode,
              })}
            />
          )}
          {currencyFilter.selectedOption === CurrencyFilterOptions.LESS_THAN && (
            <>
              <Badge
                textClass="text-dpm-gray-2 !font-normal"
                text={t('filters.currency.combined-interval', {
                  option: t(`filters.progress.${currencyFilter.selectedOption}`),
                  value: currencyFilter.lessThan?.amount ?? 0,
                  currency: currencyFilter.lessThan?.currencyCode,
                })}
              />
            </>
          )}
          {currencyFilter.selectedOption === CurrencyFilterOptions.MORE_THAN && (
            <>
              <Badge
                textClass="text-dpm-gray-2 !font-normal"
                text={t('filters.currency.combined-interval', {
                  option: t(`filters.progress.${currencyFilter.selectedOption}`),
                  value: currencyFilter.moreThan?.amount ?? 0,
                  currency: currencyFilter.moreThan?.currencyCode,
                })}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ProgressFilterOverview;
